import './Page.css'
export const Page = (props) => {
  return (
    <div className={props.title + ' page-wrapper relative'}>
      <div className='max-w-7xl mx-auto relative p-4 shadow bg-white page-content text-lg flex flex-col pb-8 sm:pb-24'>
        <div className='page-title-wrapper relative'>
          <div className='uppercase page-title inline-block py-4 relative z-10'>
            <div>{props.title}</div>
          </div>
          <div className='page-title-banner left-0 absolute inset-0 top-0 bg-cover' style={{backgroundImage: 'url(' + props.bannerImg + ')'}} />
        </div>
        {props.children}
      </div>
    </div>
  )
}