import {p1, p2, p3} from './aboutText'
import { Page } from '../Page'
import bannerImg from '../../images/aboutImg4.jpg'

function About () {
  return (
    <Page title='about' bannerImg={bannerImg}>
        <p>
          {p1}
        </p>
        <p>
          {p2}
        </p>
        <p>
          {p3}
        </p>
    </Page>
  )
}

export default About