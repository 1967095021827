import { Page } from '../Page'
import bannerImg from '../../images/servicesImg.jpg'

function Services () {
  return (
    <Page title='services' bannerImg={bannerImg}>
      <p>
        Aboriginal Resource Consultants are industry leaders in the area of Corporate/Aboriginal relations. Let our expertise and experience assist your firm in the areas of:
      </p>
      <ul className='list-disc pl-8'>
        <li>Community engagement and outreach delivered at First Nations communities</li>
        <li>Community capacity assessment</li>
        <li>Mediation</li>
        <li>Liaison Services</li>
        <li>Conflict Resolution</li>
        <li>Traditional Ecological Knowledge Studies (TEK)</li>
        <li>MIT Harvard Consensus Building Institute training facilitation</li>
        <li>Procurement</li>
        <li>Aboriginal issues awareness training</li>
        <li>Corporate Aboriginal policy development</li>
        <li>Development/implementation of communications strategies</li>
        <li>Aboriginal Government/Public Relations</li>
        <li>Research/Policy Development</li>
        <li>Interpretative Analysis</li>
        <li>Strategic Planning</li>
        <li>Communications</li>
      </ul>
    </Page>
  )
}

export default Services