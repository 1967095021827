import './Navigation.css'
import Logo from '../../images/arcgroup_logo.svg'
import React, { useState } from 'react'
import {ReactComponent as MailLogo} from '../../images/mail.svg'
import navItems from './navItems'
import { Link } from 'react-router-dom'
function Navigation() {
  let navItemClasses = "p-4 inline-flex cursor-pointer"
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    console.log('toggleMenu')
    if (!menuOpen){
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    setMenuOpen(!menuOpen)
  }
  return (
      <header className="arc-header">
        <nav className={menuOpen ? "active" : "not-active"}>
          <div className="flex items-center justify-between max-w-7xl mx-auto h-20 p-4 bg-white relative arc-mobile-wpr">
            <Link to='/' onClick={(menuOpen && toggleMenu) || undefined}><img className="arc-logo-img" alt="ARC Group Logo" src={Logo} /></Link>
              <div>
                  <button
                    className="bg-arcBlue arcblue-gradient font-semibold px-12 py-2 rounded-full text-white font-semibold button-shadow flex items-center arc-contact-btn arc-contact-btn-top"
                    data-name="info"
                    data-domain="arcgroup"
                    data-tld="ca"
                    onClick={ (data) => {
                      window.location.href = 'mailto:' + data.target.attributes['data-name'].value + '@' + data.target.attributes['data-domain'].value + '.' + data.target.attributes['data-tld'].value
                      return false
                    }

                    }
                  >
                    <MailLogo className="fill-current text-white mr-2" width={18} />
                    Contact Us
                  </button>
                  <div className={"arc-mobile-btn"} onClick={toggleMenu}>
                    <span className='bar'></span>
                    <span className='bar'></span>
                    <span className='bar'></span>
                  </div>
              </div>
          </div>
          <div className={"bg-arcBlue arcblue-gradient nav-list"}>
            <ul className="flex md:space-x-6 max-w-7xl mx-auto relative capitalize text-white font-semibold">
              {
                navItems.map(navItem => {
                  let path = navItem === 'our team' ? 'our-team' : navItem
                  return (
                    <Link key={navItem} to={path} onClick={(menuOpen && toggleMenu) || undefined}>
                      <li className={navItemClasses}>{navItem}</li>
                    </Link>
                  )
                })
              }
              <button
                className="bg-arcBlue arcblue-gradient mt-4 ml-6 font-semibold px-4 py-3 rounded-full button-shadow w-fit items-center arc-contact-btn arc-contact-btn-btm"
                data-name="info"
                data-domain="arcgroup"
                data-tld="ca"
                onClick={ (data) => {
                  window.location.href = 'mailto:' + data.target.attributes['data-name'].value + '@' + data.target.attributes['data-domain'].value + '.' + data.target.attributes['data-tld'].value
                  return false
                }

                }
              >
                <MailLogo className="fill-current mr-2" width={19} />
                Contact Us
              </button>
            </ul>
          </div>
        </nav>
      </header>
  );
}

export default Navigation;
