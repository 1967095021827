// Core modules imports are same as usual
// Direct React component imports
import './Home.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, {
  Pagination,
  Autoplay,
  EffectFade
} from 'swiper';

import 'swiper/swiper.min.css'; // core Swiper
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/pagination/pagination.min.css'; // Pagination module
import 'swiper/modules/effect-fade/effect-fade.min.css';
import React, { useEffect } from 'react';
import { heroText1, heroText2, heroText3 } from './heroText'

SwiperCore.use([Pagination, Autoplay, EffectFade]);

function Home () {
  useEffect(() => {
    let footer = document.getElementById('arc-footer')
    footer.classList.add('arc-footer-home')

    return () => {
      footer.classList.remove('arc-footer-home')
    }
  }, [])
  return (
    <div className="arc-landing-hero">
      <div className="text-white z-10 text-xl max-w-7xl mx-auto relative mt-20">
        {/* <p className="absolute hero-text">
          {heroText1}
        </p> */}
        <Swiper
          slidesPerView={1}
          className='hero-swiper'
          pagination={true}
          autoplay={{
            "delay": 12000,
            "disableOnInteraction": true
          }}
          effect={'fade'}
          fadeEffect={{
            crossFade: true
          }}
        >
          <SwiperSlide className='hero-slide'>
            {heroText1}
          </SwiperSlide>
          <SwiperSlide className='hero-slide'>
            {heroText2}
          </SwiperSlide>
          <SwiperSlide className='hero-slide'>
            {heroText3}
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}

export default Home