import './App.css'
import Navigation from '../navigation/Navigation'
import Home from '../home/Home'
import About from '../about/About'
import Services from '../services/Services'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Clients from '../clients/Clients'
import OurTeam from '../our-team/OurTeam'

function App() {
  return (
    <Router>
      <div className="ag-root">
        <Navigation />
        <main>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/clients">
              <Clients />
            </Route>
            <Route path="/our-team">
              <OurTeam />
            </Route>
            <Route path="/contact">

            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </main>
        <footer id='arc-footer' className='arc-footer relative'>
          <div className='w-full bg-arcGrey text-white p-3 italic md:text-xl bg-arcGrey relative'>
            <div className='grid grid-cols-2 max-w-7xl mx-auto text-sm'>
              <div className='justify-self-start self-center'>Project strength through design.</div>
              <div className='justify-self-end self-center'>
                <a href="tel:15062730842" title="Click to Call"><p>1-506-273-0842</p></a>
                <div className="">
                  <p>© 2021 arcgroup.ca. All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
