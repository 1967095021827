import { Page } from '../Page'
import bannerImg from '../../images/ourteamImg.jpg'
import daveImg from '../../images/davebio.jpg'
import cecilImg from '../../images/cecilbio.jpg'

function About () {
  return (
    <Page title='our-team' bannerImg={bannerImg}>
      <div className='our-team-content sm:flex sm:items-start sm:mt-10 sm:gap-x-8' style={{marginTop: 18}}>
        <img alt='David Paul' style={{marginTop: 8}} src={daveImg} />
        <div className='sm:flex-1 mt-10 sm:mt-0'>
          <h2 className='font-semibold' style={{color: '#519ad3'}}>David A. Paul, President</h2>
          <p>
            Mr. David Paul is a member of the Maliseet First Nation at Tobique, New Brunswick. David has an extensive background in First Nation issues, specifically economic development, communications, and government and corporate relations. With a career spanning over thirty five years, David has extensive experience and deep insight regarding First Nations issues, governance, economic and resource based projects and the relationships that lie therein.
          </p>

          <p>
            David served as founding director of Tobique Economic Development corporation. He was also the band manager for Tobique First Nation. Mr. Paul also served on the Federal Indian Taxation Advisory Board as their Deputy Chair from 2003 to 2005 and as its Atlantic representative from 1989 to 2007. He is currently the Deputy Chief Commissioner of the First Nations Tax Commission. David Paul has completed certification in mediation, negotiation and conflict resolution training at MIT Harvard Consencus Building Institute. David has a certificate in community planning from the University of British Columbia. He also has a certificate of project planning from the University of New Brunswick.
          </p>

          <p>
            Mr. Paul has represented the National Chief of the Assembly of First Nations at the United Nations in Geneva and New York, as part of the working committee for the United Nations Declaration on the Rights of Indigenous Peoples. He has also represented the National Chief at the Organization of American States in Washington, D.C.
          </p>

          <p>
            David Paul is also a licensed prospector in the province of New Brunswick.
          </p>
        </div>
      </div>

      <div className='sm:flex sm:items-start mt-10 sm:gap-x-8'>
        <img alt='Cecil Cameron' style={{marginTop: 8}} src={cecilImg} />
        <div className='sm:flex-1 mt-10 sm:mt-0'>
          <h2 className='font-semibold' style={{color: '#519ad3'}}>Cecil Cameron</h2>
          <p>
            Cecil Cameron is a Senior Associate with Aboriginal Resource Consultants. Mr. Cameron has worked extensively over the last 25 years with First Nations and their Leadership and has undertaken assignments at the local, provincial and regional level on their behalf.  His extensive experience includes working for both First Nations as well as their representative organizations undertaking research activities and developing policy options for consideration and implementation.   In addition to this specific experience Mr. Cameron has a background in financial management and business plan development.  Having researched and prepared over 40 business plans he has an understanding of all aspects of successful business plan development and implementation.
          </p>
        </div>
      </div>
    </Page>
  )
}

export default About