import { Page } from '../Page'
import './Clients.css'
import bannerImg from '../../images/clientsImg.jpg'

function Clients () {
  return (
    <Page title='clients' bannerImg={bannerImg}>
      <div className='client-lists flex flex-row'>
      <ul className="list-disc px-8">
        <li>Shell Canada</li>
        <li>Imperial Oil</li>
        <li>Exxon Canada</li>
        <li>Maritime Northeast Pipeline</li>
        <li>Amec Environmental Engineering</li>
        <li>Acciona Energy Canada</li>
        <li>Michigan Consolidated Gas USA</li>
        <li>Pan Canadian Resources</li>
        <li>Imperial Tobbacco Canada</li>
        <li>Irving Oil Canada</li>
        <li>PotashCorp</li>
      </ul>
      <ul className="list-disc px-8">
        <li>Atlantic Policy Congress of Chiefs</li>
        <li>Metepenagiag First Nation, NB</li>
        <li>Burnt Church First Nation, NB</li>
        <li>Tobique First Nation, NB</li>
        <li>Eel River Bar First Nation, NB</li>
        <li>Indian Island First Nation, NB</li>
        <li>Eskasoni First Nation, NS</li>
        <li>Listiguj First Nation, PQ</li>
        <li>Chippewawas on the Thames First Nation, ON</li>
      </ul>
      <ul className="list-disc px-8">
        <li>Aboriginal People's Council of Canada</li>
        <li>Indian and Northern Affairs Canada</li>
        <li>Aboriginal Affairs New Brunswick</li>
        <li>Human Resource Development Canada</li>
        <li>Atlantic Lottery Corporation</li>
        <li>MIT Harvard Consensus Building Institute</li>
        <li>Department of Health New Brunswick</li>
      </ul>
      </div>
    </Page>
  )
}

export default Clients